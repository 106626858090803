/// Constants ///
import {
    USER_LOGIN_REQUEST,USER_LOGIN_SUCCESS,USER_LOGIN_FAIL,
    USER_LOGIN_PASS_CHANGE,USER_LOGIN_PASS_CHANGE_REQUEST,USER_LOGIN_PASS_CHANGE_SUCCESS,USER_LOGIN_PASS_CHANGE_FAIL,
    USER_LOGIN_STARTUP_REQUEST,USER_LOGIN_STARTUP_SUCCESS,USER_LOGIN_STARTUP_FAIL,
    USER_LOGOUT
} from '../Constants/userConstants'

// Imports //
import {Auth} from 'aws-amplify'


/// login ///
// This action is called when the user attemps to login 
// It will also detect when a password change is required for the user 
export const login = (email, password) => async (dispatch) => {
    try{
        // Dispatch the request 
        dispatch({
            type: USER_LOGIN_REQUEST
        })
        // Now we need to log the user in 
        const user =  await Auth.signIn(email,password);
        // Now check if the user needs a password change 
        if(user.challengeName){
            // Stops the loading first, we have not signed in just in a limbo stage 
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: null
            })
            // Now dispatch action to trigger pass change 
            dispatch({
                type: USER_LOGIN_PASS_CHANGE,
                payload: user.username
            })
        }else{
            // Get the current session object also 
            const userInfo = await Auth.currentSession(); 
            // Now dispatch the success 
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: userInfo
            })
        }
    }catch(error){
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:error
        })
    }
}

/// logout ///
// This function logs out the user from the application 
// AWS-Amplify handles removing from local storage 
export const logout = () => async (dispatch) => {
    try{
        // try to logout the user with Auth package 
        await Auth.signOut();
        // Dispatch the logout action 
        dispatch({
            type: USER_LOGOUT
        })
    }catch(error){
        console.error(`Error logging out user ${error}`)
    }
}


/// passwordChange ///
// This is the action for chnaging the passwordchange state 
// It is called when a user is attempting to change there password 
export const passwordChangeAction = (username,oldPassword,newPassword) => async (dispatch) => {
    try{
        // Dispatch the request 
        dispatch({
            type: USER_LOGIN_PASS_CHANGE_REQUEST
        })
        // Now attempt to change the password 
        // First get the current user 
        const user =  await Auth.signIn(username,oldPassword);
        // Once we have old object change the password 
        await Auth.completeNewPassword(user,newPassword);
        // Get the current session so we can set the user info in state 
        // Get the session from local storage
        const userInfo = await Auth.currentSession(); 
        // Now log the user in 
        dispatch({
           type:  USER_LOGIN_SUCCESS,
           payload: userInfo
        })
        // Set the passchange to false now 
        dispatch({
            type:USER_LOGIN_PASS_CHANGE_SUCCESS
        })
    }catch(error){
        dispatch({
            type: USER_LOGIN_PASS_CHANGE_FAIL,
            payload:error
        })
    }
}


/// setInitialUser ///
// This action has the purpose of setting the initial userInfo 
// It does this by reading the userSession from local storage 
export const initialLoginAction = () => async (dispatch) => {
    try{
        // First set the loading to true for initial signup 
        dispatch({
            type: USER_LOGIN_STARTUP_REQUEST
        })
        // Now try and get the user from the current session 
        const userInfo = await Auth.currentSession(); 
        // If we have gotten this it is a success so set the userInfo 
        dispatch({
            type: USER_LOGIN_STARTUP_SUCCESS,
            payload: userInfo
        })
    }catch(error){
        dispatch({
            type: USER_LOGIN_STARTUP_FAIL,
            payload:error
        })
    }

}