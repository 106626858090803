import React,{useEffect} from 'react'

// Bootstrap 
import {Container,ButtonGroup,Button} from 'react-bootstrap'

// Redux //
// useDipatch, useSelector - Dispatch action, get piece of state 
import {useSelector} from 'react-redux' 


const Hubs = ({history}) => {

    // Redux state 
	//const dispatch  = useDispatch()
	const userLogin = useSelector(state => state.userLogin)
    const {userInfo}  = userLogin

    // useEffect //
    // Check if the user is not logged in 
    // If not they shouldnt be on this page 
    useEffect(() => {
        if(!userInfo){
            history.push('/login')
        }
    },[userInfo,history])


    const smartThingsHandler = () =>{
        window.location.href = "https://api.smartthings.com/oauth/authorize?client_id=ec7527ba-5538-44f2-b6d5-a85858c00754&response_type=code&redirect_uri=https://api.cosygrid.com/smartthings/OAuth&scope=r:locations:*%20r:devices:*%20x:devices:*%20w:devices:*"
    }

    return(
        <Container className="text-center py-3">
            <h1>Please Select a Type of Hub</h1>
                    <ButtonGroup className="p-5" vertical size='lg'>
                        <Button className="bg-info" variant="outline-dark" onClick={smartThingsHandler}>Samsung SmartThings</Button>
                        <Button className="bg-info" variant="outline-dark">Hub A</Button>
                        <Button className="bg-info" variant="outline-dark">Hub B</Button>
                    </ButtonGroup>
        </Container>
    )
}

export default Hubs; 