import React from 'react'

// Bootsrap //
import {Container,Row,Col} from 'react-bootstrap'

const Home = () => {
    return(
        <Container>
            <Row>
                <Col className="text-center py-3">
                    <h1>CosyGrid</h1>
                </Col>
            </Row>
            <Row>
                <Col className="text-justify">
                    <p>
                        Hello
                    </p>
                </Col>
            </Row>
        </Container>
        )
}

export default Home
