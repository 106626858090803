import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

/// Redux ///
import {Container,Row,Col,ListGroup} from 'react-bootstrap'

/// NewPasswordForm ///
// Form for holding the new password page
// This form is used when user required to chnag epassword on initial login 
// props
//  handleSubmit - Function to call when the form is submitted 
//  setNewPassword  - Function to set the password state 
//  newPassword     - The actual state of the password to be set 
//  setOldPassword  - Function for setting state of old password 
//	oldPassword     - The actual state of the old password to be set 
function NewPasswordForm({handleSubmit,setNewPassword,newPassword,setOldPassword,oldPassword}){
    /// Validate the form ///
    // Will toggle the button enabled/disabled based upon return value  
    function validateForm() {
        return newPassword.length > 0;
    }
    return (
		<Container>
			<Row className='justify-content-md-center'>
				<Col xs={12} md={6}>
					<Form onSubmit={handleSubmit}>

						<Form.Group size="lg" controlId="oldPassword">
	                		<Form.Label>Old Password</Form.Label>
	                		<Form.Control
	                			type="password"
	                			value={oldPassword}
	                			onChange={(e) => setOldPassword(e.target.value)}
								placeholder="Enter Old Password"
							/>
              			</Form.Group>

                		<Form.Group size="lg" controlId="newPassword">
                		<Form.Label>New Password</Form.Label>
                		<Form.Control
                			type="password"
                			value={newPassword}
                			onChange={(e) => setNewPassword(e.target.value)}
							placeholder="Enter Password"
						/>
              			</Form.Group>
				
                		<Button block size="lg" type="submit" disabled={!validateForm()}>
                  		Confirm Password
                		</Button>
            		</Form>
				</Col>
			</Row>
			<Row>
				<Col>
					<h3 className="text-center py-4">Password requirements</h3>
				</Col>
			</Row>
			<Row className="justify-content-md-center py-1">
				<Col xs={12} md={6}>
					<ListGroup>
						<ListGroup.Item className="justify-content-md-center">Minimum Length 8 characters</ListGroup.Item>
						<ListGroup.Item className="justify-content-md-center">Contains Numbers</ListGroup.Item>
						<ListGroup.Item className="justify-content-md-center">Contains Lower and Upper case Letters</ListGroup.Item>
						<ListGroup.Item className="justify-content-md-center">Contains Symbols</ListGroup.Item>
					</ListGroup>
				</Col>
			</Row>
		</Container>
    )
}

export default NewPasswordForm