import React, { useState,useEffect } from "react";
import LoginForm from '../../Components/Auth/Login/LoginForm/LoginForm'
import "./Login.css"
import { useHistory } from "react-router-dom";

// Bootstarp //
import {Container} from 'react-bootstrap'

/// Components ///
import Message from '../../Components/Message/Message'
import Loader from '../../Components/Loader/Loader'

/// Redux ///
import {useDispatch,useSelector} from 'react-redux'

// Actions //
import {login} from '../../Actions/userActions'

function Login(){
    // Use state to capture email and password  
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

	// Redux state 
	const dispatch                            = useDispatch()
	const userLogin                           = useSelector(state => state.userLogin)
    const userPasswordChange                  = useSelector(state => state.userPasswordChange)
	const {loginLoading,loginError,userInfo}  = userLogin
    const {passwordChange}                    = userPasswordChange

    // history to redirect //
    const history = useHistory()

	/// handleSubmit ///
    // This function is fired when the submit button is clicked 
    // The function logs the user in 
	const handleSubmit = async (e) => {
		e.preventDefault()
        dispatch(login(email,password))
	}

    // UseEffect //
    // Called when component loads 
    useEffect(() => {
        // If there is something in the userInfo state 
        if(passwordChange){
            // We want to redirect as a logged in user should not be able to visit this screen 
            history.push('/login/newpassword')
        }else if(userInfo){
            history.push('/')
        }
    },[passwordChange,userInfo,history])

    	return (
            <Container>
                <h1 className="text-center py-3">Login</h1>
                {loginError && <Message variant='danger'>{loginError.message}</Message>}
                {loginLoading && <Loader/>}
				<LoginForm 
					handleSubmit = {handleSubmit}
					setEmail     = {setEmail}
					email        = {email}
					setPassword  = {setPassword}
					password     = {password}
				/>   
            </Container>
        );
}

export default Login;