import React from 'react'
import {Container, Nav,Navbar,NavDropdown} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'

// Redux //
// useDipatch, useSelector - Dispatch action, get piece of state 
import {useDispatch, useSelector} from 'react-redux' 

// Actions //
import {logout} from '../../Actions/userActions'

const Header = () => {
    // dispatch for calling actions
    const dispatch = useDispatch()

    // Redux state 
	//const dispatch  = useDispatch()
	const userLogin = useSelector(state => state.userLogin)
    const {userInfo}  = userLogin

    // logoutHandler //
    // This is used when the logout button is clicked
    // STILL NEEDS TO BE IMPLMENTED  
    const logoutHandler = (e) => {
        // Dispatch the logout action 
        dispatch(logout())
    }

    return( 
        <header>
            <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to='/'>
                        <Navbar.Brand href="#home">CosyGrid</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                        <LinkContainer to='/'>
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                        {userInfo ? (
                            <NavDropdown title={userInfo.accessToken.payload.username} id='username'>
                                <LinkContainer to='/hub'>
                                    <NavDropdown.Item>Hub</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Item onClick={logoutHandler}>
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        ):
                        <LinkContainer to='/login'>
                            <Nav.Link>Login</Nav.Link>
                        </LinkContainer>
                        }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header