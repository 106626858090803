import React, { useEffect, useState }  from "react";
import axios from 'axios';
import './Welcome.css'
function Welcome(){
    /// state - susbscriptions ///
    const [subscriptions, setSubscriptions] = useState("");


    /// useEffect ///
    // Will run every time the component is re rendered 
    //useEffect(() => {
    //    // Function must be defined first if Async //
    //    async function fetchData(){
    //        // Send the data to the backend 
    //        let res = await axios.get('/subscriptions')
    //        console.log(res.data.message)
    //        for (let item of res.data.message){
    //            console.log(item)
    //        }
    //        setSubscriptions(res.data.message)
    //    }
    //    fetchData()
    //},[])


    return(
        <div className="Welcome">
            <h1>
                <span className="font-weight-bold">CosyGrid</span>
            </h1>
            <h2>Thank You For Signing Up!</h2>
          {/*  {subscriptions.map((sub,index) => (
                <div key={index}>
                    <h1>{sub.sourceType}</h1>
                </div>
          ))} */}
        </div>
    )
}

export default Welcome;
