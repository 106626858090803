// AWS //
import {Auth} from 'aws-amplify'

// Redux
import {createStore,combineReducers,applyMiddleware} from 'redux' 
import thunk from "redux-thunk" 

/// Dev tools ///
import {composeWithDevTools} from 'redux-devtools-extension'

/// Reducers ///
import {userLoginReducer,userPasswordChangeReducer} from './Reducers/userReducers'

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userPasswordChange: userPasswordChangeReducer
})


/// IntialState ///
// User information from localstorage 
const userInfoFromStorage = (localStorage.getItem('userInfo') && localStorage.getItem('userInfo') !== 'undefined') ? JSON.parse(localStorage.getItem('userInfo')) : null
const initialState = {
    userLogin: {userInfo: userInfoFromStorage}
}

// Middleware 
const middleware = [thunk]


// Create the store 
const store = createStore(reducer,initialState,composeWithDevTools(applyMiddleware(...middleware)));

export default store;