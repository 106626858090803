import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './config'

/// Redux ///
import {Provider} from 'react-redux'
import store from './store'

// Configures AWS Amplify package with the cognito credentials for Login 
Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId:config.cognito.APP_CLIENT_ID,
    mandatorySignIn: true
  }
})


// Render the App component to the DOM 
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
