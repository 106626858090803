export const USER_LOGIN_REQUEST              = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS              = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL                 = 'USER_LOGIN_FAIL'
export const USER_LOGIN_PASS_CHANGE          = 'USER_LOGIN_PASS_CHANGE'
export const USER_LOGIN_PASS_CHANGE_SUCCESS  = 'USER_LOGIN_PASS_CHANGE_SUCCESS'
export const USER_LOGIN_PASS_CHANGE_REQUEST  = 'USER_LOGIN_PASS_CHANGE_REQUEST'
export const USER_LOGIN_PASS_CHANGE_FAIL     = 'USER_LOGIN_PASS_CHANGE_FAIL'
export const USER_LOGIN_STARTUP_REQUEST      = 'USER_LOGIN_STARTUP_REQUEST'
export const USER_LOGIN_STARTUP_SUCCESS      = 'USER_LOGIN_STARTUP_SUCCESS'
export const USER_LOGIN_STARTUP_FAIL         = 'USER_LOGIN_STARTUP_FAIL'
export const USER_LOGOUT                     = 'USER_LOGOUT'