/// Constants ///
import {USER_LOGIN_REQUEST,USER_LOGIN_SUCCESS,USER_LOGIN_FAIL,
    USER_LOGIN_PASS_CHANGE,USER_LOGIN_PASS_CHANGE_FAIL,USER_LOGIN_PASS_CHANGE_REQUEST,USER_LOGIN_PASS_CHANGE_SUCCESS,
    USER_LOGIN_STARTUP_REQUEST,USER_LOGIN_STARTUP_SUCCESS,USER_LOGIN_STARTUP_FAIL,
    USER_LOGOUT
} from '../Constants/userConstants'

/// userLoginReducer ///
// This reducer manages the state for userLogin 
export const userLoginReducer = (state ={}, action) => {
    switch (action.type) {
        /// Login ///
        case USER_LOGIN_REQUEST:
            return {loginLoading: true}
        case USER_LOGIN_SUCCESS:
            return {loginLoading: false, userInfo: action.payload}
        case USER_LOGIN_FAIL:
            return {loginLoading: false, loginError: action.payload}
        // Logout 
        case USER_LOGOUT:
            console.log("CALLED")
            return {loginLoading: false, userInfo: null}
        /// Initial Automatic Login ///
        case USER_LOGIN_STARTUP_REQUEST:
            return {initialLoginLoading:true}
        case USER_LOGIN_STARTUP_SUCCESS:
            return {initialLoginLoading:false,userInfo: action.payload}
        case USER_LOGIN_STARTUP_FAIL:
            return {initialLoginLoading:false, userInfo:null,initialLoginError:action.payload}
        default:
            return state
    }
}

/// userPasswordChangeReducer ///
// This is used for when a user is required to change there password 
// Called from the login and passwordChangeAction 
export const userPasswordChangeReducer = (state, action) => {
    switch (action.type){
        /// Password Change ///
        case USER_LOGIN_PASS_CHANGE:
            return {passwordChangeLoading: false,passwordChange: true,username:action.payload}
        case USER_LOGIN_PASS_CHANGE_REQUEST:
            return {passwordChangeLoading: true,username:state.username}
        case USER_LOGIN_PASS_CHANGE_SUCCESS:
            return {passwordChangeLoading: false, passwordChange:false}
        case USER_LOGIN_PASS_CHANGE_FAIL:
            return {passwordChangeLoading: false, passwordChangeError: action.payload, username:state.username}
        default:
            return {...state}
    }
}
