import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./LoginForm.css"

/// loginForm ///
// Holds the form for the login page 
// props
//  handleSubmit    - The function to call when the submit button is pressed 
//  setEmail        - Function to call to set the email state 
//  email           - The email state to be set 
//  setPassword     - Function to call to set the email state  
//  password        - The password state to be set 
function loginForm(props){

    /// Validate the form ///
    // Used to disable the login button if criteria not met 
    function validateForm() {
        return props.email.length > 0 && props.password.length > 0;
    }

    // JSX to be retruned 
    return(
            <Form className="login-form" onSubmit={props.handleSubmit}>
              <Form.Group size="lg" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  placeholder="Username"
                  value={props.email}
                  type="text"
                  onChange={(e) => props.setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group size="lg" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  placeholder="Password"
                  type="password"
                  value={props.password}
                  onChange={(e) => props.setPassword(e.target.value)}
                />
              </Form.Group>
              <Button block size="lg" type="submit" disabled={!validateForm()}>
                Login
              </Button>
            </Form>
    )
}


export default loginForm