// Imports //
import React, {useEffect} from "react"
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

// Pages
import Home from './Pages/Home/Home'
import Login from './Pages/Login/Login'
import Hubs from './Pages/Hub/Hub'
import Welcome from './Pages/Welcome/Welcome'
import NotFound from './Pages/NotFound/NotFound'
import InternalServer from './Pages/InternalServer/InternalServer'
import SmartthingsRegistration from './Pages/SmartthingsRegistration/SmartthingsRegistration'
import NewPassword from './Pages/NewPassword/NewPassword'


// Components 
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import Loader from './Components/Loader/Loader'


/// Redux ///
import {useDispatch,useSelector} from 'react-redux'

// Actions //
import {initialLoginAction} from './Actions/userActions'

// Main App component 
function App() {
	// Redux State //
	const dispatch  = useDispatch()
	const userLogin = useSelector(state => state.userLogin)
	const {initialLoginLoading} = userLogin

	// UseEffect //
    // Called when component loads 
    useEffect(() => {
		// Dispatch the action to login initially 
		dispatch(initialLoginAction())
	},[dispatch])


	// If we have not finished trying to log in then load a spinner 
	if(initialLoginLoading){
		return(<Loader></Loader>)
	}else{
		return (
			<Router>
		   		<Header />
		    	    <Switch>
		    		    <Route exact path="/" component={Home}/>
		    		    <Route exact path="/login" component={Login}/>
						<Route exact path="/hub" component={Hubs}/>
		    		    <Route exact path="/login/newpassword" component={NewPassword}/>
		    		    <Route exact path="/welcome" component={Welcome}/>
		    		    <Route path="/smartthingsregistration/:installedAppID/:authToken/:refreshToken/" component={SmartthingsRegistration} />
		    		    <Route exact path="/500" component={InternalServer}/>
		    		    <Route component={NotFound} />
		    	    </Switch>
		   		<Footer /> 
		   	</Router>
  );
	}
  
}

export default App;
