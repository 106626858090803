import React from "react";
import {Link} from 'react-router-dom';

// Bootstarp //
import {Container} from 'react-bootstrap'


function NotFound(){
    return(
        <Container className="text-center py-3">
            <h1>404 - Not Found</h1>
            <p>The page you where looking for could not be found</p>
            <Link to="/">
                Go To Home Page
            </Link>
        </Container>
    )
}

export default NotFound;