import React,{useEffect} from 'react'
import { useParams } from "react-router-dom";
import {Container,Row,Col} from 'react-bootstrap'
import axios from 'axios';


// Redux //
// useDipatch, useSelector - Dispatch action, get piece of state 
import {useSelector} from 'react-redux' 


const SmartthingsRegistration = ({match,path}) => {
    const {installedAppID,authToken,refreshToken} = useParams()

    // Redux state 
	//const dispatch  = useDispatch()
	const userLogin = useSelector(state => state.userLogin)
    const {userInfo}  = userLogin


    console.log(userInfo)

    useEffect(() => {
        async function postData(){
            let data = {
                    appID:installedAppID,
                    accessToken:authToken,
                    refreshToken:refreshToken
            }
            let res = await axios.post('https://api.cosygrid.com/smartthings/OAuth',data,{
                headers: {
                    'Authorization': userInfo.idToken.jwtToken
                }
                }
            )
            console.log(res)
    } 
    if(userLogin.initialLoginLoading === false){
        postData()
    }
    },[installedAppID,authToken,refreshToken,userInfo,userLogin.initialLoginLoading])
    
    
    //let appID = match.parmas.installedAppID
   // console.log(appID)


    //postData(match.parmas.installedAppID,
      //  match.params.authToken,
      //  match.params.locationID,
      //  match.params.refreshToken)
    
    return(
        <Container className="text-center py-3">
            <Row>
                <Col>
                <h2>Thank you for registering a SmartThings Hub!</h2>
                </Col>
            </Row>
        </Container>
    )
}

export default SmartthingsRegistration;