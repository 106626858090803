import React, { useState,useEffect } from "react";

// Bootstarp //
import {Container} from 'react-bootstrap'

// Actions //
import {passwordChangeAction} from '../../Actions/userActions'

/// Redux ///
import {useDispatch,useSelector} from 'react-redux'

/// Components ///
import Message from '../../Components/Message/Message'
import Loader from '../../Components/Loader/Loader'
import NewPasswordForm from '../../Components/Auth/NewPassword/NewPasswordForm/NewPasswordForm'


/// NewPassword ///
// Used for when a new user logins for the first time and 
// requires a new password to be configured 
function NewPassword({history}){
    // States for password 
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");

	// Redux state 
	const dispatch = useDispatch()
	const userPasswordChange  = useSelector(state => state.userPasswordChange)
	const userLogin           = useSelector(state => state.userLogin)
	const {passwordChangeLoading,passwordChange,passwordChangeError,username} = userPasswordChange
	const {userInfo}  = userLogin

	/// handelSubmit ///
	// Function called on submitting the form 
	const handleSubmit = async (e) => {
		e.preventDefault()
		dispatch(passwordChangeAction(username,oldPassword,newPassword))
	}

	// UseEffect //
    // Called when component loads 
    useEffect(() => {
        // If the userInfo is set then user should not be able to access this page, in future change this to passwordChange
        if(userInfo){
            // We want to redirect as a logged in user should not be able to visit this screen 
            history.push('/')
		}
    },[userInfo,history])


	// Return the NewPassWordForm 
    return(
		<Container>
			<h1 className="text-center py-2">New Password Required</h1>
			{passwordChangeError && <Message variant='danger'>{passwordChangeError.message}</Message>}
            {passwordChangeLoading && <Loader/>}
			<NewPasswordForm 
				handleSubmit={handleSubmit}
				setNewPassword={setNewPassword}
				newPassword={newPassword}
				setOldPassword={setOldPassword}
				oldPassword={oldPassword}
		/>
		</Container>
    )
}

export default NewPassword