import React from "react";
import {Link} from 'react-router-dom';

// Bootstarp //
import {Container} from 'react-bootstrap'


function Error(){
    return(
        <Container className="text-center py-3">
            <h1>500 - Internal Server Error</h1>
            <p>An internal server error has occured</p>
            <Link to="/">
                Go To Home Page
            </Link>
        </Container>
    )
}

export default Error;